<template>
  <validation-observer ref="simpleRules">
    <b-card title="Ekle">
      <b-row>
        <b-col>
          <projects />
        </b-col>
        <b-col>
          <types />
        </b-col>
        <b-col>
          <blocks />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <floor />
        </b-col>
        <b-col>
          <number />
        </b-col>
        <b-col>
          <price />
        </b-col>
      </b-row>
    </b-card>
    <save-button :action-methods="submitForm" />
  </validation-observer>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import Projects from '@/views/Realty/Stocks/forms/Projects.vue'
import Types from '@/views/Realty/Stocks/forms/Types.vue'
import Blocks from '@/views/Realty/Stocks/forms/Blocks.vue'
import Floor from '@/views/Realty/Stocks/forms/Floor.vue'
import Price from '@/views/Realty/Stocks/forms/Price.vue'
import Number from '@/views/Realty/Stocks/forms/Number.vue'
import SaveButton from '@/views/Realty/Stocks/forms/SaveButton.vue'

export default {
  name: 'Add',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Projects,
    Types,
    Blocks,
    Floor,
    Price,
    Number,
    SaveButton,
  },
  data() {
    return {
      submitStatus: false,
      options: [{ id: '1', label: 'Aktif' }, { id: '0', label: 'Pasif' }],
    }
  },
  computed: {
    realtyNumber() {
      return this.$store.getters['realtyNumbers/getRealty_sale_number']
    },
    saveStatus() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numberSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.realtyNumber.submitStatus = true
          this.$store.dispatch('realtyNumbers/realty_sale_numberSave', this.realtyNumber)
        }
      })
    },
  },
}
</script>
